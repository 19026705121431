<template>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <Component :is="currentStepType"></Component>
    </div>
  </div>
</div>
</template>
<script>
import { computed, defineAsyncComponent } from '@vue/runtime-core'
import { useStore } from 'vuex'
// Load layout components dynamically.
const requireContext = require.context('~/pages/system/steps', false, /.*\.vue$/, 'sync')
const componentNames = requireContext.keys().map((file) => file.replace(/(^.\/)|(\.vue$)/g, ''))
const components = {}
componentNames.forEach((component) => { // component represents the component name
  components[component] = defineAsyncComponent({
    loader: () => import('@/pages/system/steps/' + component + '.vue'),
    delay: 0,
    suspensible: false
  })
})
export default {
  components,
  setup () {
    const store = useStore()
    const currentStepType = computed(() => {
      return store.getters['system/currentStep'].type
    })
    return {
      currentStepType
    }
  }
}
</script>
