<template>
  <div>
    <h3 class="heading-title" v-if="step.name === 'Muster'">Wählen Sie jetzt das Muster für Ihre Zaunanlage.</h3>
    <h3 class="heading-title" v-if="step.name === 'Form'">Wählen Sie jetzt die Verlaufsform aus.</h3>
    <h3 v-if="step.description" class="step-description">{{ step.description }}</h3>
    <div class="mb-4" v-if="step.name == 'Muster'">
      <button class="btn" :class="{ 'btn-warning': showAll, 'btn-outline-warning': !showAll }" @click="showAllModel">
        <span v-if="!showAll">Alle Muster anzeigen</span>
        <span v-else-if="showAll">Alle Muster verbergen</span>
      </button>
    </div>
    <div v-if="showAll" class="row">
      <div v-for="option in options" :key="option.id" class="col-2 mb-4">
        <component :is="(option.type || 'select') + 'Option'" :key="option.id" :option="option" />
      </div>
    </div>
    <Slider v-if="!showAll"
    ref="slider"
    :itemClass="step.css"
    :init-page="startPage"
    chevron="true"
    :key="componentKey">
      <component v-for="option in options"
      :is="(option.type || 'select') + 'Option'"
      :key="option.id"
      :option="option"
      :type="step.css"/>
    </Slider>
  </div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import Slider from '~/pages/configurator/common/slider'
import SelectOption from './option/selectOption'
import CountOption from './option/countOption'
export default {
  name: 'Simpleselect',
  components: {
    Slider,
    selectOption: SelectOption,
    countOption: CountOption,
    checkOption: SelectOption
  },
  setup () {
    const store = useStore()
    const showAll = ref(false)
    const page = ref(1)
    const perPage = ref(6)
    const componentKey = ref(0)

    const step = computed(() => {
      return store.getters['system/currentStep']
    })
    const currentOptions = computed(() => {
      return store.getters['system/currentOptions']
    })
    const startPage = computed(() => {
      const index = currentOptions.value.findIndex(o => o.selected)
      const page = Math.ceil((index + 1) / perPage.value)
      if (page === 0) {
        return 1
      } else {
        return page
      }
    })
    const options = computed(() => {
      return currentOptions.value
    })
    function showAllModel () {
      showAll.value = !showAll.value
    }
    return {
      showAll,
      page,
      perPage,
      step,
      componentKey,
      options,
      startPage,
      showAllModel
    }
  }
}
</script>
